import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Grid, Icon, ToggleButton, ToggleButtonGroup, Box, Tooltip } from '@popmenu/common-ui';
import { Typography } from '@material-ui/core';
import { Info } from '@popmenu/web-icons';
import { notBlankValidator } from '../../utils/forms';
import { withIntl } from '../../utils/withIntl';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import updateUserMutation from '../../libs/gql/mutations/users/updateUserMutation.gql';

import BasicForm, { CheckBoxGroup, SelectGroup, SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import BirthdayFields from '../../consumer/shared/user/BirthdayFields';
import { useStyles } from './styles';

const formatOnboardingRole = onboardingRole => (
  onboardingRole?.replace('role_', '').split('_').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
);

const withoutOnboardingRole = (obj) => {
  const { onboardingRole, ...rest } = obj;
  return rest;
};

const UserProfileForm = ({ currentUser, isAdmin, restaurant, t }) => {
  const onboardingRole = restaurant && currentUser.members.find(member => member.restaurantId === restaurant.id)?.userSelectedRole;
  // hiding ability for dark mode until further support is added
  // leaving ability for user to switch back to light if they're currently on dark mode
  const isHidden = currentUser.adminPaletteType !== 'admin_palette_dark';
  const [phoneTypeField, setPhoneTypeField] = useState(currentUser.isMobilePhone ? 'mobile' : 'phone');
  const isMobile = phoneTypeField === 'mobile';
  const handlePhoneTypeChange = (event, value) => {
    event.preventDefault();
    if (value !== null) {
      setPhoneTypeField(value);
    }
  };
  const classes = useStyles();
  return (
    <BasicForm
      defaultValues={{
        adminPaletteType: currentUser.adminPaletteType,
        birthDay: currentUser.birthDay,
        birthMonth: currentUser.birthMonth,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        onboardingRole: formatOnboardingRole(onboardingRole),
        optInInformationalTexts: currentUser.optInInformationalTexts,
        optInPromotionalTexts: currentUser.optInPromotionalTexts,
        phone: currentUser.phone,
      }}
      labelPosition="top"
      mutate={{
        mutation: updateUserMutation,
        toVariables: variables => ({
          userId: currentUser.id,
          userInput: {
            ...withoutOnboardingRole(variables),
            birthDay: variables.birthDay ? parseInt(variables.birthDay, 10) : null,
            birthMonth: variables.birthMonth ? parseInt(variables.birthMonth, 10) : null,
            isMobilePhone: isMobile,
            optInInformationalTexts: isMobile ? variables.optInInformationalTexts : false,
            optInPromotionalTexts: isMobile ? variables.optInPromotionalTexts : false,
          },
        }),
      }}
    >
      <TextFieldGroup
        disabled={currentUser.isRoot}
        field="email"
        title={t('models.user.email')}
        type="email"
        validate={notBlankValidator}
        variant="standard"
      />
      <TextFieldGroup
        field="firstName"
        title={t('models.user.first_name')}
        variant="standard"
      />
      <TextFieldGroup
        field="lastName"
        title={t('models.user.last_name')}
        variant="standard"
      />
      <Grid container spacing={2} className={classes.gridPhone}>
        <Grid item xs={12} md={8} lg={9}>
          <TextFieldGroup
            field="phone"
            title={t('models.user.phone')}
            type="tel"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} className={classes.gridToggle}>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={phoneTypeField}
            color="primary"
            onChange={handlePhoneTypeChange}
          >
            <ToggleButton value="phone" data-tour-id="settings-my-profile-phone">
              {t('models.user.phone')}
            </ToggleButton>
            <ToggleButton value="mobile" data-cy="settings-my-profile-mobile" data-tour-id="settings-my-profile-mobile">
              {t('models.user.mobile')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Typography
        variant="subtitle1"
        className={classes.titleTooltip}
        style={{ display: isMobile ? 'flex' : 'none' }}
        data-tour-id="settings-my-profile-opt-in-description"
      >
        {t('models.user.textNotifications')}
        <Tooltip title={(
          <React.Fragment>
            {t('models.user.optInTooltip.informationalTextsDescription')}
            <br /><br />
            {t('models.user.optInTooltip.promotionalTextsDescription')}
          </React.Fragment>
        )}
        >
          <Box component="span" ml={1} display="inline-flex" alignItems="center">
            <Icon size="medium" icon={Info} aria-label="Additional info" />
          </Box>
        </Tooltip>
      </Typography>

      <Grid container className={classes.gridContainer} style={{ display: isMobile ? 'flex' : 'none' }}>
        <Grid item xs={6}>
          <CheckBoxGroup
            className={classes.checkboxTitle}
            field="optInInformationalTexts"
            title={(
              <span className={classes.checkboxTitle}>
                {t('models.user.optInInformationalTexts')}
              </span>
            )}
            data-tour-id="settings-my-profile-opt-in-informational-texts"
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBoxGroup
            className={classes.checkboxTitle}
            field="optInPromotionalTexts"
            title={(
              <span className={classes.checkboxTitle}>
                {t('models.user.optInPromotionalTexts')}
              </span>
            )}
            data-tour-id="settings-my-profile-opt-in-promotional-texts"
          />
        </Grid>
      </Grid>
      <BirthdayFields />
      {isAdmin && onboardingRole && (
        <TextFieldGroup
          disabled
          field="onboardingRole"
          title={t('models.user.role')}
          variant="standard"
        />
      )}
      {isAdmin && !isHidden && (
        <SelectGroup
          field="adminPaletteType"
          isClearable={false}
          options={[
            {
              label: 'Light',
              value: 'admin_palette_light',
            },
            {
              label: 'Dark',
              value: 'admin_palette_dark',
            },
          ]}
          title="My Popmenu Theme"
          variant="standard"
        />
      )}
      <SubmitGroup
        color="primary"
        justify="center"
        title={t('users.update_profile')}
        data-tour-id="settings-my-profile-update-profile-button"
      />
    </BasicForm>
  );
};

UserProfileForm.defaultProps = {
  isAdmin: false,
};

UserProfileForm.propTypes = {
  currentUser: currentUserShape.isRequired,
  isAdmin: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
)(UserProfileForm);
