import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

import Grid from '../Grid';

const AuthySummary = () => (
  <Grid container>
    <Grid item xs={12}>
      <Typography align="center">
        <FormattedMessage
          id="authy_summary.disabled"
          defaultMessage="Multi-factor authentication is disabled."
        />
        <br />
        <a href="https://authy.com/what-is-2fa/" rel="noreferrer" target="_blank">
          <FormattedMessage
            id="authy_summary.what_is_2fa"
            defaultMessage="What is MFA?"
          />
        </a>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography align="center">
        <Button component={Link} to="/authenticate">
          <FormattedMessage
            id="authy_summary.setup_button"
            defaultMessage="Set up Multi-factor Auth"
          />
        </Button>
      </Typography>
    </Grid>
  </Grid>
);

export default AuthySummary;
